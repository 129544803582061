
.paddingBottom80 {
  padding-bottom: 80px !important;
}
.titleAc {
  color: var(--text-white);
  text-decoration: underline;
  font-size: 1.25rem;
  font-family: Ubuntu;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
.superCenter {
  display: block;
  margin: 0 auto !important;
}
.expansionPanel {
  box-shadow: none !important;
  border: 2px solid var(--primary-main);
  padding: 3px 0px 4px 0px;
}

.greyBg {
  background-color: #f1f1f1 !important;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes whatsapp-button-animation {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.95);
  }
}
@keyframes whatsapp-button-animation-2 {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 10px 0 rgba(77, 156, 65, 0);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 10px 2px var(--primary-main);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 10px 0 rgba(77, 156, 65, 0);
  }
}



.img {
  width: 100%;
  height: auto;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.container {
  padding: 2.5rem;
  width: 100%;
}

.home-container {
  padding: 8rem 0;
  width: 100%;
}

.noRadius fieldset {
  border-radius: 0;
}

.spacing-top {
  margin-top: 3rem;
}

.spacing-top-xs {
  margin-top: 0.5rem;
}

.spacing-bottom {
  margin-bottom: 3rem;
}

.ribbon2 {
  width: 49px;
  padding: 4px 0;
  position: absolute;
  color: var(--text-white);
  top: -6.6px;
  left: -7px;
  font-weight: bold;
  text-align: center;
  font-size: 1rem;
  border-top-left-radius: 3px;
  background: var(--secondary-main);
  -webkit-box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.11);
  box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.11);
}
.ribbon2 span {
  font-weight: 400;
  font-size: 1rem;
  margin-top: -6px;
  margin-left: -6px;
  display: block;
}
.ribbon2:before {
  height: 0;
  width: 0;
  right: -5.5px;
  top: 0.1px;
  border-bottom: 6px solid var(--secondary-main);
  border-right: 6px solid transparent;
  -webkit-box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.11);
  box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.11);
}
.ribbon2:before,
.ribbon2:after {
  content: "";
  position: absolute;
}
.ribbon2:after {
  height: 0;
  width: 0;
  bottom: -10px;
  left: 0;
  border-left: 24px solid var(--secondary-main);
  border-right: 25px solid var(--secondary-main);
  border-bottom: 10px solid transparent;
}

.ribbon3 {
  width: 45px;
  padding: 4px 0;
  position: absolute;
  color: var(--text-white);
  top: -6.6px;
  left: -7px;
  font-weight: bold;
  text-align: center;
  font-size: 0.9rem;
  border-top-left-radius: 3px;
  background: var(--secondary-main);
  -webkit-box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.11);
  box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.11);
}
.ribbon3 span {
  font-weight: 400;
  font-size: 0.9rem;
  margin-top: -6px;
  margin-left: -6px;
  display: block;
}
.ribbon3:before {
  height: 0;
  width: 0;
  right: -5.5px;
  top: 0.1px;
  border-bottom: 6px solid var(--secondary-main);
  border-right: 6px solid transparent;
  -webkit-box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.11);
  box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.11);
}
.ribbon3:before,
.ribbon3:after {
  content: "";
  position: absolute;
}
.ribbon3:after {
  height: 0;
  width: 0;
  bottom: -10px;
  left: 0;
  border-left: 24px solid var(--secondary-main);
  border-right: 21px solid var(--secondary-main);
  border-bottom: 10px solid transparent;
}

#units::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-100);
  border-radius: 10px;
}

#units::-webkit-scrollbar {
  width: 10px;
  background-color: var(--neutral-100);
}

#units::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--neutral-50);
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#4d9c41),
    to(#19911d),
    color-stop(0.6, #54de5d)
  );
}

.userMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 250px;
  height: auto;
  right: 2rem;
  visibility: hidden;
  top: 70px;
  opacity: 0;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s, opacity 1s, visibility 0.1s;
}

.userHover:hover .userMenu {
  /*targets the action on the container*/
  opacity: 1;
  visibility: visible;
  transform: translateY(20px);
}

@media screen and (max-width: 450px) {
  .ribbon2 {
    width: 35px;
    font-size: 0.8rem;
  }
  .ribbon2 span {
    font-size: 0.675rem;
    margin-top: -4px;
  }
  .ribbon2:after {
    bottom: -9px;
    border-left: 18px solid var(--secondary-main);
    border-right: 17px solid var(--secondary-main);
    border-bottom: 9px solid transparent;
  }
  .container {
    padding: 20px;
  }
}

@media screen and (max-width: 390px) {
  .ribbon2 {
    width: 30px;
    font-size: 0.675rem;
  }
  .ribbon2 span {
    font-size: 0.675rem;
    margin-top: -3px;
  }
  .ribbon2:after {
    bottom: -9px;
    border-left: 15px solid var(--secondary-main);
    border-right: 15px solid var(--secondary-main);
    border-bottom: 9px solid transparent;
  }
}
.userMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 250px;
  height: auto;
  background: var(--neutral-50);
  right: 0.5rem;
  visibility: hidden;
  top: 15px;
  opacity: 0;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s, opacity 1s, visibility 0.1s;
}

.userHover:hover .userMenu {
  /*targets the action on the container*/
  opacity: 1;
  visibility: visible;
  transform: translateY(20px);
}

.userHover {
  position: relative;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid var(--neutral-700) !important;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid var(--neutral-700) !important;
}
